<template>
  <div class="main">
    <div class="home">
      <!-- 轮播图 -->
      <div>
        <div class="home_banner">
          <el-carousel
            height="50vh"
            loop
            :interval="3000"
            :autoplay="autoplayStatus"
            indicator-position="none"
            ref="carousel"
            @change="labelMark"
          >
            <el-carousel-item
              v-for="(item, index) in bannerImg"
              :key="index"
              class="home_banner_item"
            >
              <el-image
                class="home_banner_img"
                :src="item.img_url"
                fit="cover"
                @click="openBanner(item.id)"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="labelList">
          <div
            :class="
              labelIndex == index
                ? 'labelList_item labelList_items'
                : 'labelList_item'
            "
            v-for="(item, index) in bannerImg.length"
            :key="index"
            @click="setActiveItem(index)"
          ></div>
        </div>
      </div>
      <!-- 专题内容 -->
      <div class="topic">
        <div
          v-for="(item, index) in topicList"
          :key="index"
          class="topic_model"
          @click="openTopic(item.id)"
        >
          <img :src="item.cover" />
        </div>
        <div
          class="topic_text"
          @click="openTopicList"
          v-if="topicList.length > 4"
        >
          <div>查</div>
          <div>看</div>
          <div>更</div>
          <div>多</div>
        </div>
      </div>
      <!-- 分类列表 -->
      <div>
        <sort-card></sort-card>
      </div>
    </div>
  </div>
</template>

<script>
import sortCard from "./components/sortCard.vue";
export default {
  components: {
    sortCard,
  },
  data() {
    return {
      labelIndex: 0,
      autoplayStatus: true,
      bannerImg: [],
      topicList: [],
    };
  },
  created() {
    this.getBanner();
    this.getTopic();
  },
  methods: {
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
      this.labelIndex = index;
      this.autoplayStatus = false;
      setTimeout(() => {
        this.autoplayStatus = true;
      }, 0);
    },
    labelMark(e) {
      this.labelIndex = e;
    },
    // 获取主图
    getBanner() {
      this.$axios({
        url: this.$api + "/auth/index/slideshow",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.bannerImg = res.data.data;
        }
      });
    },
    // 打开主图
    openBanner(e) {
      this.$router.push({
        path: "/HomeDetails",
        query: {
          id: e,
        },
      });
    },
    // 获取专题列表
    getTopic() {
      this.$axios({
        url: this.$api + "/auth/ppt/album/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data.res;
          this.topicList = list.slice(0, 5);
        }
      });
    },
    openTopic(e) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: e,
        },
      });
    },
    openTopicList() {
      this.$router.push({
        path: "/topicList",
      });
    },
  },
};
</script>

<style scoped>
.home {
  width: 80%;
  margin: 10px auto 0;
}
@media screen and (max-width: 1500px) {
  .home {
    width: 1200px;
  }
}
.home_banner {
  border-radius: 15px;
  overflow: hidden;
}
.home_banner_img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.labelList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 10%;
  padding: 10px;
  margin: 0 auto;
  top: -45px;
  z-index: 10;
}
.labelList_item {
  border: 3px solid #fdd30f;
  border-radius: 100%;
  padding: 3px;
  width: 3px;
  height: 3px;
}
.labelList_items {
  background-color: #fdd30f;
}

.topic {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.topic_model {
  width: 18%;
  margin: 0 1%;
  height: 15vh;
  border-radius: 10px;
}
.topic_model > img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.topic_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  height: 15vh;
  color: #000;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fdd30f;
  box-sizing: border-box;
  cursor: default;
}
</style>