<template>
  <div>
    <div class="cart_model">
      <!-- 个人信息 -->
      <div class="cart_model_user">
        <div>
          <img :src="userInfo.picture" />
        </div>
        <div>{{userInfo.username}}</div>
        <div>策划师</div>
      </div>
      <!-- 用户数据 -->
      <div class="cart_model_userData">
        <div>
          <div>作品</div>
          <div>{{userInfo.product_num}}</div>
        </div>
        <!-- <div></div>
        <div>
          <div>粉丝</div>
          <div>354</div>
        </div> -->
      </div>
      <!-- 关注按钮 -->
      <!-- <div class="cart_model_btn">
        <div>关注</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props:['userInfo'],
};
</script>

<style scoped>

.cart_model {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.cart_model_user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.cart_model_user img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}
.cart_model_user > div:nth-of-type(2) {
  font-size: 16px;
  color: #000;
}
.cart_model_user > div:nth-of-type(3) {
  font-size: 14px;
  color: #949494;
}
.cart_model_userData {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-around;
}
.cart_model_userData > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
  padding: 10px;
  margin: 10px;
  width: 40%;
  box-sizing: border-box;
  border-top: 1px solid #e3e3e3;
}
.cart_model_userData > div:nth-of-type(2) {
  border: 1px solid #e3e3e3;
  height: 30px;
  padding: 0;
  margin: 0;
  width: 0;
}
.cart_model_userData > div > div:nth-of-type(2) {
  font-size: 12px;
  color: #949494;
}
.cart_model_btn {
  width: 80%;
  color: #000;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  background-color: #eeeeee;
  border: 1px solid #c0c4cc;
}
.cart_model_btn:active {
  background-color: #ffffffa9;
}
</style>