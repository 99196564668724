<template>
  <div>
    <!-- 分类操作栏 -->
    <div class="sort">
      <div class="sort_icon">
        <div @click="unfold">
          <i
            class="el-icon-arrow-right"
            :style="
              sortStatus
                ? 'transform: rotate(90deg);transition: transform .5s;'
                : 'transition: transform .5s;'
            "
          ></i>
          <div class="sort_icon_title">方案分类</div>
        </div>
      </div>
      <div class="sort_model" :style="sortStatus ? 'max-height: 100vh;' : 'max-height: 0px;'">
        <div class="sort_item" v-for="(item, index) in sortList" :key="index">
          <div class="sort_itemTitle">{{ item.title }}：</div>
          <div class="sort_itemBox">
            <div
              class="sort_itemSubclass"
              v-for="(list, listIndex) in item.children"
              :key="listIndex"
            >
              <div
                :style="list.state ? 'background-color: #F1DC64;' : ''"
                @click="passID(list.id, index, listIndex)"
              >{{ list.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 排序筛选 -->
    <div class="filter">
      <div class="filter_title">排序</div>
      <div class="filter_list">
        <!-- 最新 -->
        <div
          class="filter_list_item"
          :style="listParameter.new ? 'color: green;' : ''"
          @click="newestData"
        >最新上传</div>
        <!-- 最热 -->
        <div
          class="filter_list_item"
          :style="listParameter.hot ? 'color: green;' : ''"
          @click="hottest"
        >最热下载</div>
      </div>
    </div>
    <!-- 列表内容 -->
    <div class="card_list">
      <!-- 列表内容项 -->
      <div
        class="card_list_item"
        v-for="(item, index) in list"
        :key="index"
        @click="openDetails(item)"
      >
        <!-- 卡片图片 -->
        <div class="card_list_itemImg">
          <img :src="item.cover" />
        </div>
        <!-- 卡片内容 -->
        <div class="card_list_itemContent">
          <div class="card_list_itemContentText">
            <div class="itemContentText_title">{{ item.title }}</div>
            <div class="itemContentText_cat">{{ item.cat_title }}</div>
          </div>

          <!-- 卡片标题 -->
          <!-- <el-popover placement="top-start" width="200" trigger="hover" :open-delay="500">
          <user-model></user-model>-->
          <!-- 发布人 -->
          <div class="card_list_itemContentUser" slot="reference">
            <div>
              <img :src="item.picture" />
            </div>
            <div>{{ item.username }}</div>
          </div>
          <!-- </el-popover> -->
        </div>
        <!-- 卡片数据 -->
        <div class="card_list_itemData">
          <div class="card_list_itemData_start">
            <div>
              <div>
                <i class="el-icon-view"></i>
              </div>
              <div>{{ item.visit_num }}</div>
            </div>
            <div>
              <div>
                <i class="iconfont icondianzan"></i>
              </div>
              <div>{{ item.like_num }}</div>
            </div>
          </div>
        </div>
        <div style="padding: 0px 20px 20px 20px; text-align: end; color: #888;">{{item.created}}</div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="card_pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        :page-size="20"
        :current-page="listParameter.page"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import userModel from "@/components/userModel.vue";
export default {
  data() {
    return {
      sortStatus: true,
      sortList: null,
      // 发送的参数
      listParameter: {
        page: 1,
        title: "",
        username: "",
        cat_id: "",
        hot: "",
        new: ""
      },
      // 方案数据
      list: [],
      // 方案数据中数量
      count: 0,
      // 被选中id合集
      sortId: []
    };
  },
  created() {
    this.getSort();
    this.getList();
  },
  components: {
    userModel
  },
  methods: {
    unfold() {
      this.sortStatus = !this.sortStatus;
    },
    // 打开详情
    openDetails(e) {
      this.$router.push({
        path: "/HomeDetails",
        query: {
          id: e.id,
          visit: e.visit_num,
          like: e.like_num
        }
      });
    },
    // 初始化列表
    getList() {
      let listParameter = this.listParameter;
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;"
        },
        params: {
          page: listParameter.page,
          title: listParameter.title,
          username: listParameter.username,
          cat_id: listParameter.cat_id,
          hot: listParameter.hot,
          new: listParameter.new
        }
      }).then(res => {
        if (res.data.code == 200) {
          let list = res.data.data;
          this.list = list.res;
          this.count = list.count;
        }
      });
    },
    // 初始化分类
    getSort() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;"
        }
      }).then(res => {
        if (res.data.code == 200) {
          let list = res.data.data;
          list.map(item => {
            if (!item.children) {
              item.children = [];
            }
            item.children.map(list => {
              list.state = false;
            });
          });
          this.sortList = list;
        }
      });
    },
    // 最新上传
    newestData() {
      this.listParameter.new = !this.listParameter.new;
      this.getList();
    },
    // 最热下载
    hottest() {
      this.listParameter.hot = !this.listParameter.hot;
      this.getList();
    },
    // 分类id保存
    passID(id, index, listIndex) {
      if (this.sortId.length == 0) {
        this.sortId.push(id);
        this.sortList[index].children[listIndex].state = true;
      } else {
        let state = this.sortId.findIndex(item => item == id);
        if (state == -1) {
          this.sortId.push(id);
          this.sortList[index].children[listIndex].state = true;
        } else {
          this.sortId.splice(state, 1);
          this.sortList[index].children[listIndex].state = false;
        }
      }
      this.handleSort();
    },
    // 获取相应分类的数据
    handleSort() {
      let id = "";
      this.sortId.map((item, index) => {
        if (this.sortId.length == index + 1) {
          id += item;
        } else {
          id += item + ",";
        }
      });
      this.listParameter.cat_id = id;
      this.getList();
    },
    // 当前页发生变化
    handleCurrentChange(val) {
      this.listParameter.page = val;
      this.getList();
    }
  }
};
</script>
<style scoped>
/* 分类操作栏 */
.sort {
  margin: 30px 0;
}
.sort_icon > div {
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sort_icon_title {
  margin: 0 10px;
}
.sort_model {
  overflow: hidden;
  transition: max-height 0.5s;
}
.sort_item {
  font-weight: 600;
  font-size: 1rem;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
}
.sort_itemTitle {
  cursor: default;
  min-width: 120px;
  padding-top: 10px;
}
.sort_itemBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sort_itemSubclass {
  font-weight: normal;
  margin: 5px 10px;
  cursor: pointer;
}
.sort_itemSubclass > div {
  height: 30px;
  padding: 3px 10px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
}

/* 排序筛选 */
.filter {
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-starts;
}
.filter_title {
  padding: 0 40px;
  border-right: 1px solid #666;
}
.filter_list {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* 卡片内容列表 */
.card_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card_list_item {
  width: 21%;
  font-size: 14px;
  border-radius: 10px;
  margin: 1% 2%;
  background-color: #fff;
  transition: box-shadow 0.5s;
}
.card_list_item:hover {
  box-shadow: 7px 7px 7px #e3e3e3, -7px -7px 7px #f4f4f4;
}
.card_list_itemImg {
  width: 100%;
  height: 20vh;
}
.card_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 10px;
}
.card_list_itemImg > img:hover {
  transform: scale(1.5);
}
.card_list_itemContent {
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.card_list_itemContentText {
  box-sizing: border-box;
  padding: 20px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemContentText_title {
  font-size: 16px;
  font-weight: 600;
}
.itemContentText_cat {
  font-size: 10px;
  color: #666;
  margin: 10px 0;
}
.card_list_itemContentUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemContentUser > div {
  box-sizing: border-box;
  margin: 0px 10px 20px 10px;
}
.card_list_itemContentUser img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.card_list_itemData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_list_itemData_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemData_start > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData {
  padding: 0px 20px 5px 20px;
  color: #666;
  box-sizing: border-box;
}
.card_list_itemData i {
  font-size: 20px;
  margin: 0 10px;
  box-sizing: border-box;
}
/* 分页 */
.card_pagination {
  margin: 20px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #fdd30f;
  color: #000;
}
.card_pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  background-color: #fdd30f;
}
</style>